/**
 * SimpleCellTree
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { List } from '@exode.ru/vkui';

import { If } from '@/components/Utils';

import { Container } from './SimpleCellTree.styled';


interface Props {
    list: {
        parent: {
            id: number;
            element: ReactElement;
        };

        items?: ReactElement | ReactElement[]
    }[] | undefined | null;
    /** Left space of child items (padding) */
    mode?: 'offset' | 'aligned';
    className?: string;
    itemsWrapperClassName?: string;
    expandState?: Record<string, boolean>;
}


const SimpleCellTree = (props: Props) => {

    const {
        list,
        className,
        expandState,
        itemsWrapperClassName,
        mode = 'offset',
    } = props;

    return (
        <Container className="flex flex-col gap-4">
            {list?.map(({ parent, items }) => (
                <div className={className}>
                    {parent.element}

                    <If is={!expandState || expandState[parent.id]}>
                        {_.isArray(items)
                            ? (
                                <If is={(items || [])?.length > 0}>
                                    <List className={[
                                        itemsWrapperClassName,
                                        mode === 'offset' ? 'items-list' : '',
                                    ].join(' ')}>
                                        {items?.map((item: ReactElement) => item)}
                                    </List>
                                </If>
                            )
                            : (
                                <If is={!!items}>
                                    <List className={[
                                        itemsWrapperClassName,
                                        mode === 'offset' ? 'items-list' : '',
                                    ].join(' ')}>
                                        {items}
                                    </List>
                                </If>
                            )}
                    </If>
                </div>
            ))}
        </Container>
    );
};


export { SimpleCellTree };
