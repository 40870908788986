/**
 * ProgressButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Button, ButtonProps, Spinner } from '@exode.ru/vkui';

import { Container } from './ProgressButton.styled';


export interface ProgressButtonProps extends ButtonProps {
    progress?: number;
    type?: 'static' | 'dynamic';
    wrapperClassName?: string;
    progressClassName?: string;
}


const ProgressButton = (props: ProgressButtonProps) => {

    const {
        after,
        before,
        loading,
        disabled,
        children,
        wrapperClassName,
        progressClassName,
        progress = 0,
        type = 'dynamic',
        ...rest
    } = props;

    const { t } = useI18n('components.Atoms.ProgressButton');

    return (
        <Container className={[ wrapperClassName, 'relative overflow-hidden' ].join(' ')}>
            <Button {...rest} loading={false}
                    disabled={disabled || loading}
                    after={loading ? <></> : after}
                    before={loading ? <></> : before}>
                <div className="relative z-[1] flex items-center justify-center gap-2">
                    <If is={!!loading}>
                        <Spinner size="small" className="w-fit"/>
                    </If>

                    <div>
                        {loading ? t('loading') : children}
                    </div>

                    <If is={!!loading}>
                        <span className="mb-[1px]">{Math.min(progress, 99)}%</span>
                    </If>
                </div>

                <If is={!!loading || type === 'static'}>
                    <div style={{ width: `calc(${progress}% - 8px)` }} className={[
                        progressClassName,
                        'progress-line absolute',
                    ].join(' ')}/>
                </If>
            </Button>
        </Container>
    );
};


export { ProgressButton };
