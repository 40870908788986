/**
 * LessonCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { observer, PreferenceStore } from '@/store/preference/preference';

import {
    CourseFindOneLessonsQueryResult,
    CourseLessonFindManyQueryResult,
    CourseLessonFindOneQueryResult,
} from '@/codegen/graphql';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { CourseService } from '@/services/Course/Course';

import {
    CourseFindManyItem,
    CourseFindOne,
    CourseLessonItems,
    CourseProgressCurrentItem,
    CurrentLesson,
} from '@/types/course';

import { RichCell } from '@exode.ru/vkui';
import { Avatar } from '@exode.ru/vkui/tokenized';
import { Icon24ChevronRightCircle, Icon28PictureOutline } from '@vkontakte/icons';

import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { getCourseCartColors } from '@/components/Course/CourseCard/helper';

import { LessonAfterIconPart } from './parts/LessonAfterIconPart';
import { LessonAvatarBadgePart } from './parts/LessonAvatarBadgePart';

import { Container } from './LessonCard.styled';


type LessonType = CurrentLesson
    | CourseLessonItems[number]
    | CourseProgressCurrentItem['lesson']
    | NonNullable<GqlResult<CourseLessonFindManyQueryResult>['courseLessonFindMany']['items']>[number]

export interface LessonCardProps {
    course: CourseFindOne
        | CourseFindManyItem
        | CourseProgressCurrentItem['course']
        | GqlResult<CourseLessonFindOneQueryResult>['courseFindOne']
        | GqlResult<CourseFindOneLessonsQueryResult>['courseFindOne'];
    lesson: LessonType & { items?: LessonType[]; };
    order?: number;
    size?: 'default' | 'compact';
    active?: boolean;
    colorful?: boolean;
    onClick?: () => void;
    dataTest?: string;
    className?: string;
    captionTags?: ReactNode;
    hideBaseCaption?: boolean;
    openLessonLoading?: boolean;
    expandState?: Record<string | number, boolean>;
    onExpandClick?: (lessonId: number, expanded?: boolean) => void;
}


const LessonCard = observer((props: LessonCardProps) => {

    const {
        course,
        active,
        lesson,
        onClick,
        dataTest,
        colorful,
        className,
        captionTags,
        expandState,
        onExpandClick,
        hideBaseCaption,
        openLessonLoading,
        size = 'default',
        order = lesson.order,
        lesson: {
            name,
            video,
            wrapper,
            hasVideo,
            withPractice,
        },
    } = props;

    const { t } = useI18n('components.Course.LessonCard');

    return (
        <Container {...props} onClick={onClick} className={[
            className,
            colorful && getCourseCartColors(course.id, PreferenceStore.isDark),
        ].join(' ')}>
            <RichCell multiline
                      hasHover={false}
                      hasActive={false}
                      data-test={dataTest}
                      data-value={lesson.id}
                      className={[
                          'cursor-pointer',
                          size === 'compact' ? 'min-h-0' : '',
                      ].join(' ')}
                      after={(
                          <div className="flex items-center">
                              <If is={!!lesson?.items?.length}>
                                  <Avatar size={24}
                                          shadow={false}
                                          className="bg-transparent cursor-pointer"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              onExpandClick?.(lesson.id);
                                          }}>
                                      <Icon24ChevronRightCircle fill="var(--accent)" className="transition-all" style={{
                                          transform: expandState?.[lesson.id]
                                              ? 'rotate(-90deg)'
                                              : 'rotate(-270deg)',
                                      }}/>
                                  </Avatar>
                              </If>

                              <LessonAfterIconPart course={course} lesson={lesson} isLoading={openLessonLoading}/>
                          </div>
                      )}
                      caption={(
                          <div className="flex flex-wrap items-center gap-1">
                              <If is={!hideBaseCaption}>
                                  <span>{t('lesson')} {order}</span>

                                  <If is={!!video?.duration}>
                                      <span>{' • '}</span>
                                      <TagInlineText gradient="gray"
                                                     className="min-w-[29px]"
                                                     content={CourseService.formatDuration(video?.duration || 0)}/>
                                  </If>

                                  <If is={withPractice}>
                                      <span>{' • '}</span>
                                      <TagInlineText gradient="green" className="min-w-[18px]" content={t('homework')}/>
                                  </If>
                              </If>

                              <>{captionTags}</>
                          </div>
                      )}
                      before={(
                          <Avatar mode="app" src={wrapper?.small || ''} badge={(
                              <LessonAvatarBadgePart hasVideo={hasVideo} badgeSize={size === 'default' ? 12 : 10}/>
                          )}>
                              <If is={!wrapper}>
                                  <Icon28PictureOutline/>
                              </If>
                          </Avatar>
                      )}>
                <span className={[
                    'break-words text-ellipsis',
                    active ? 'text-accent' : 'text-primary',
                    size === 'compact' ? 'line-clamp-1' : 'line-clamp-2',
                ].join(' ')}>
                    {name}
                </span>
            </RichCell>
        </Container>
    );
});


export { LessonCard };
